function Title (){
    return(<div className="self-center max-w-full aspect-[4.76] w-[258px]">
          <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b1729e4916a44aeb84a1539059da8609eafe89e85cd40ee851a2e1499fec7e67?apiKey=02d92fbd5fb14097898c1e0a25d70d27&"
        className="self-center max-w-full aspect-[4.76] w-[258px]"
      />
    </div>);

   
}

export default Title ;